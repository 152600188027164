import axios from 'axios';





export const MakePayment = async (ticketName, email, ticketPrice) => {

    let data = JSON.stringify({
        "amount": ticketPrice,
        "currency": "USD",
        "ticketName": ticketName,
        "email": email
      });
      
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://paynow.tango.co.zw/initiate-payment',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      
    try {
        const response = await axios.request(config);
        console.log(JSON.stringify(response.data));
        const payment = response.data; 
        console.log(payment)
        return payment; 
    } catch (error) {
        console.log(error);
        return null; 
    }
}

export const confirmPayment = async (pollUrl) => {
    let data = JSON.stringify({
        "pollUrl": pollUrl
      });
      
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://paynow.tango.co.zw/confirm-payment',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      
      try {
        const response = await axios.request(config);
        console.log(JSON.stringify(response.data));     
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}