
import axios from "axios"

export const sendEmail = async  (email,qrCode) =>{ 

  let data = JSON.stringify({
    "recipientName": email,
    "qrCodeUrl": qrCode,
    "ticketDownloadUrl": 'ticketName',
    "email": email
  });
 

  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: 'https://paynow.tango.co.zw/send-ticket-email',
    headers: { 
      'Content-Type': 'application/json'
    },
    data : data
  };

 
  try {
    const response = await axios.request(config);
    console.log(JSON.stringify(response.data));
    console.log('Email sent successfully:', response.data);
    alert('Email sent successfully!'); 
} catch (error) {
  console.error('Failed to send email:', error);
  alert('Failed to send email. Please try again.');
}

 
};


