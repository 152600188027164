import {createClient} from '@supabase/supabase-js';


// Initialize Supabase client
// const supabaseUrl = process.env.REACT_APP_SUPABASE_DEV_URL;
// const supabaseKey = process.env.REACT_APP_SUPABASE_DEV_KEY;

const supabaseUrl = process.env.REACT_APP_SUPABASE_PROD_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_PROD_KEY;

export const supabase = createClient(supabaseUrl, supabaseKey);
