import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import PaymentSuccessPage from './components/PaymentSuccessPage';
import Header from "./components/header";
import EventDetails from './pages/EventDetails';
import Home from "./pages/Home";
import Organizers from './pages/Organizers';
import PaymentSuccessPageWeb from './components/PaymentSuccessPageWeb';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/organizers" element={<Organizers />} />
        {/* <Route path="/event-details/:id" element={<EventDetails />} /> */}
        <Route path="/event" element={<EventDetails />} />
        <Route path="/payment-success" element={<PaymentSuccessPage />} />
        <Route path="/payment-success-web" element={<PaymentSuccessPageWeb />} />
      </Routes>
    </Router>
  );
}

export default App;
