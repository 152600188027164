import { supabase } from "../api";

export default async function createEventTicketPayment(paymentData) {
    
    if (!paymentData || typeof paymentData !== 'object') {
        console.error('Invalid payment data:', paymentData);
        return null;
    }

    const { data, error } = await supabase
        .from('event_ticket_payments')
        .insert([
            paymentData
        ])
        .select();
       
    if (error) {
        console.error('Error creating payment:', error);
        return null;
    }
    return data[0];
}

export async function updatePaymentStatus(id) {
   
    const { data: ticketOrderData, error: ticketOrderError } = await supabase
        .from('event_ticket_payments')
        .select('*')
        .eq('id', id)         
        .single();

    if (ticketOrderError) {
        console.error('Error fetching ticket order:', ticketOrderError);
        return null;
    }

    if (ticketOrderData) {           
        const { data: updateOrderData, error: updateOrderError } = await supabase
            .from('event_ticket_payments')
            .update({ payment_success: true }) 
            .eq('id', id) 
            

        if (updateOrderError) {
            console.error('Error updating ticket order:', updateOrderError);
            return null;
        }
        return updateOrderData; // Return updated ticket order data
    } else {        
        const { data: updateData, error: updateError } = await supabase
            .from('event_ticket_payments')
            .update({ payment_success: true }) // Set payment_success to true
            .eq('id', id)
            .select() // Match user_id
            
        if (updateError) {
            console.error('Error updating payment status:', updateError);
            return null;
        }
        return updateData; // Return updated payment data
    }
}

export async function fetchAllPaymentRecords(page = 1, limit = 10) {
    // Fetch all payment records from the event_ticket_payments table with pagination
    const { data, error } = await supabase
        .from('event_ticket_payments')
        .select('*') // Select all fields
        .range((page - 1) * limit, page * limit - 1); // Implement pagination

    if (error) {
        console.error('Error fetching payment records:', error);
        return null;
    }
    return data; // Return the fetched payment records
}
