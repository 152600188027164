import { Ticket } from "lucide-react";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import {
  signInWithEmail,
  verifyOtp,
  createEventCustomAnswer,
  upsertUser,
} from "../utils/auth.js";
import { supabase } from "../api.js";
import { MakePayment } from "../utils/paynow.js";
import TopLoader from "./TopLoader.js";
import { useNavigate } from "react-router-dom";
import createEventTicketPayment, {
  fetchAllPaymentRecords,
} from "../utils/database.js";

export default function PaymentForm({ onPayment, tickets, questions, event }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [isOtp, setIsOtp] = useState(false);
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [session, setSession] = useState(null);
  const [check, setCheck] = useState(true);
  const [numberOfTickets, setNumberOfTickets] = useState(1); // New state for number of tickets
  const navigate = useNavigate();
  console.log(tickets.ticket_type == "free");

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const fetchSession = async () => {
    try {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      console.log("session", session);
      setSession(session?.user ?? null);
    } catch (error) {
      console.log(error);
    }
  };

  const totalPrice = tickets?.price * tickets?.service_fee + tickets?.price;

  const overall =
    numberOfTickets * (tickets?.price * tickets?.service_fee + tickets?.price);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = answers.find(
      (item) => item.questionId.includes("email") // Updated to check for inclusion
    )?.answer;
    console.log(email);
    if (
      answers.find(
        (item) => item.questionId.includes("email") // Updated to check for inclusion
      )?.answer
    ) {
      setLoading(true);

      try {
        const login = await signInWithEmail(email);
        setIsOtp(true);
        console.log("login", login);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };
  const handleCheckboxChange = (questionId, isChecked, value) => {
    setAnswers((prevAnswers) => {
      const existingAnswer = prevAnswers.find(
        (answer) => answer.questionId === questionId
      );

      if (existingAnswer) {
        const updatedValues = existingAnswer.answer || [];
        const newValues = isChecked
          ? [...updatedValues, value]
          : updatedValues.filter((v) => v !== value);
        return prevAnswers.map((answer) =>
          answer.questionId === questionId
            ? { ...answer, answer: newValues }
            : answer
        );
      } else {
        return [
          ...prevAnswers,
          { questionId, answer: [value], type: "checkbox" },
        ];
      }
    });
  };

  const handleInputChange = (questionId, value) => {
    setAnswers((prevAnswers) => {
      const existingAnswerIndex = prevAnswers.findIndex(
        (answer) => answer.questionId === questionId
      );

      if (existingAnswerIndex !== -1) {
        const updatedAnswers = [...prevAnswers];
        updatedAnswers[existingAnswerIndex].answer = value;
        return updatedAnswers;
      } else {
        return [...prevAnswers, { questionId, answer: value }];
      }
    });
  };

  const handleSubmitFinal = async () => {
    setLoading(true);
    const user = JSON.parse(localStorage.getItem("User"));
    const email = answers.find((item) =>
      item.questionId.includes("email")
    )?.answer;

    // Prepare user data from mandatory answers
    const userData = {
      email: email,
      first_name: mandatoryAnswers.firstName,
      last_name: mandatoryAnswers.lastName,
      phone: mandatoryAnswers.phoneNumber,
      dob: mandatoryAnswers.idDate,
      gender: mandatoryAnswers.gender,
    };

    // Upsert user data
    const fire = await upsertUser(user.id, userData);
    console.log(fire);

    const paymentData = {
      user_id: user.id,
      event_id: event.id,
      ticket_id: tickets.id,
      poll_url: "",
      use_points: false,
      ticket_price: tickets?.price * numberOfTickets,
      admission_count: numberOfTickets,
      payment_success: false,
      service_fee_amount:
        tickets?.price * tickets?.service_fee * numberOfTickets,
      payment_summary_url: "",
    };

    if (tickets.ticket_type == "free") {
      if (questions && questions?.length > 0) {
        const record = await createEventCustomAnswer(
          user.id,
          event.id,
          answers
        );
      }
      const paymentRecord = {
        created_at: new Date().toISOString(),
        user_id: user.id,
        event_id: event.id,
        ticket_id: tickets.id,
        poll_url: "none",
        use_points: false,
        ticket_price: tickets?.price * numberOfTickets,
        admission_count: numberOfTickets,
        payment_success: true,
        service_fee_amount:
          tickets?.price * tickets?.service_fee * numberOfTickets,
        payment_summary_url: "none",
      };
      const paymentData = {
        user_id: user.id,
        event_id: event.id,
        ticket_id: tickets.id,
        poll_url: "none",
        use_points: false,
        ticket_price: tickets?.price * numberOfTickets,
        admission_count: numberOfTickets,
        payment_success: false,
        service_fee_amount:
          tickets?.price * tickets?.service_fee * numberOfTickets,
        payment_summary_url: "",
      };
      const payment = await createEventTicketPayment(paymentData);
      localStorage.setItem("payment", JSON.stringify(payment));

      localStorage.setItem("paymentRecord", JSON.stringify(paymentRecord));
      navigate("/payment-success-web");
    } else {
      if (questions && questions?.length > 0) {
        const record = await createEventCustomAnswer(
          user.id,
          event.id,
          answers
        );
        const data = await MakePayment(tickets.name, email, overall);

        const paymentRecord = {
          created_at: new Date().toISOString(),
          user_id: user.id,
          event_id: event.id,
          ticket_id: tickets.id,
          poll_url: data.pollUrl,
          use_points: false,
          ticket_price: tickets?.price * numberOfTickets,
          admission_count: numberOfTickets,
          payment_success: true,
          service_fee_amount:
            tickets?.price * tickets?.service_fee * numberOfTickets,
          payment_summary_url: "",
        };
        const urlObject = new URL(data.pollUrl);
        const guidParam = urlObject.searchParams.get("guid");
        const paymentData = {
          user_id: user.id,
          event_id: event.id,
          ticket_id: tickets.id,
          poll_url: data.pollUrl,
          use_points: false,
          ticket_price: tickets?.price * numberOfTickets,
          admission_count: numberOfTickets,
          payment_success: false,
          service_fee_amount:
            tickets?.price * tickets?.service_fee * numberOfTickets,
          payment_summary_url: `https://www.paynow.co.zw/Transaction/TransactionView/?guid=${guidParam}`,
        };
        const payment = await createEventTicketPayment(paymentData);

        localStorage.setItem("paymentRecord", JSON.stringify(paymentRecord));
        setLoading(false);
        if (data.redirectUrl) {
          window.location.href = data.redirectUrl;
        }
      } else {
        const data = await MakePayment(tickets.name, email, overall);
        const paymentRecord = {
          created_at: new Date().toISOString(),
          user_id: user.id,
          event_id: event.id,
          ticket_id: tickets.id,
          poll_url: data.pollUrl,
          use_points: false,
          ticket_price: tickets?.price * numberOfTickets,
          admission_count: numberOfTickets,
          payment_success: true,
          service_fee_amount:
            tickets?.price * tickets?.service_fee * numberOfTickets,
          payment_summary_url: "",
        };
        const urlObject = new URL(data.pollUrl);
        const guidParam = urlObject.searchParams.get("guid");
        const paymentData = {
          user_id: user.id,
          event_id: event.id,
          ticket_id: tickets.id,
          poll_url: data.pollUrl,
          use_points: false,
          ticket_price: tickets?.price * numberOfTickets,
          admission_count: numberOfTickets,
          payment_success: false,
          service_fee_amount:
            tickets?.price * tickets?.service_fee * numberOfTickets,
          payment_summary_url: `https://www.paynow.co.zw/Transaction/TransactionView/?guid=${guidParam}`,
        };
        const payment = await createEventTicketPayment(paymentData);
        localStorage.setItem("payment", JSON.stringify(payment));

        localStorage.setItem("paymentRecord", JSON.stringify(paymentRecord));
        setLoading(false);
        if (data.redirectUrl) {
          window.location.href = data.redirectUrl;
        }
      }
    }
  };

  const handleSubmitOtp = async (e) => {
    e.preventDefault();
    if (otp) {
      try {
        setLoading(true);
        const email = answers.find((item) =>
          item.questionId.includes("email")
        )?.answer;
        setAnswers((prevAnswers) =>
          prevAnswers.map((answer) => ({
            ...answer,
            questionId: answer.questionId.replace("*email", "").trim(),
          }))
        );

        const response = await verifyOtp(email, otp);
        console.log("OTP Response:", response.user.id);
        localStorage.setItem("User", JSON.stringify(response.user));
        setCheck(false);

        setLoading(false);
      } catch (error) {
        console.error("Error verifying OTP:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const mandatoryQuestions = [
    { id: "firstName", label: "First Name" },
    { id: "lastName", label: "Last Name" },
    { id: "phoneNumber", label: "Phone Number" },
    { id: "idDate", label: "DOB" },
    { id: "gender", label: "Gender" },
  ];

  const [mandatoryAnswers, setMandatoryAnswers] = useState({});

  return (
    <div>
      <button
        onClick={toggleModal}
        className="block w-full py-3 mt-4 text-center text-white bg-[#147481] rounded hover:bg-white-600"
      >
        Purchase Ticket
      </button>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 p-4">
          <div className="bg-white p-10 rounded-xl shadow-lg max-w-3xl w-full overflow-auto max-h-[80vh] overflow-y-auto">
            {!isOtp && check && (
              <h2 className="text-xl font-bold mb-6 text-center">
                Registration Form
              </h2>
            )}
            {isOtp && check && (
              <h4 className="text-xl font-bold mb-6 text-center">
                Enter your Otp Code
              </h4>
            )}
            {loading && <TopLoader />}

            {!isOtp && check && (
              <form onSubmit={handleSubmit} className="space-y-6 overflow-auto">
                {mandatoryQuestions.map((question) => (
                  <div className="mb-4" key={question.id}>
                    <label className="block text-sm font-medium mb-2">
                      {question.label} <span className="text-red-500">*</span>
                    </label>
                    {question.id === "gender" ? (
                      <select
                        required
                        className="border p-2 w-full rounded"
                        onChange={(e) => {
                          const value = e.target.value;
                          setMandatoryAnswers((prev) => ({
                            ...prev,
                            [question.id]: value,
                          }));
                        }}
                        defaultValue=""
                      >
                        <option value="" disabled>
                          Select your gender
                        </option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select>
                    ) : (
                      <input
                        type={question.id === "idDate" ? "date" : "text"}
                        placeholder={`Enter your ${question.label.toLowerCase()}`}
                        required
                        className="border p-2 w-full rounded"
                        onChange={(e) => {
                          const value = e.target.value;
                          setMandatoryAnswers((prev) => ({
                            ...prev,
                            [question.id]: value,
                          }));
                        }}
                      />
                    )}
                  </div>
                ))}

                {/* Render Email Input only if no question with answer type "email" exists */}
                {!questions.some(
                  (q) => q.answer_type.toLowerCase() === "email"
                ) && (
                  <div className="mb-4">
                    <label className="block text-sm font-medium mb-2">
                      Email <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="email"
                      placeholder="Enter your email"
                      required
                      className="border p-2 w-full rounded"
                      onChange={(e) =>
                        handleInputChange("email", e.target.value)
                      }
                    />
                  </div>
                )}
                {questions && questions.length > 0 ? (
                  <>
                    {/* Check if any question has answer type "email" */}
                    {questions.map((question) => (
                      <div key={question.id} className="mb-4">
                        <label className="block text-sm font-medium mb-2">
                          {question.question}{" "}
                          {question.is_required && (
                            <span className="text-red-500">*</span>
                          )}
                        </label>

                        {/* Text Input */}
                        {question.answer_type.toLowerCase() === "text" && (
                          <input
                            type="text"
                            name={question.id}
                            placeholder={`Enter ${question.question}`}
                            required={question.is_required}
                            className="border p-2 w-full rounded"
                            onChange={(e) =>
                              handleInputChange(question.id, e.target.value)
                            }
                          />
                        )}

                        {/* Email Input */}
                        {question.answer_type.toLowerCase() === "email" && (
                          <input
                            type="email"
                            name={question.id}
                            placeholder={`Enter ${question.question}`}
                            required={question.is_required}
                            className="border p-2 w-full rounded"
                            onChange={(e) =>
                              handleInputChange(
                                question.id + "*" + "email",
                                e.target.value
                              )
                            }
                          />
                        )}

                        {/* Number Input */}
                        {question.answer_type.toLowerCase() === "number" && (
                          <input
                            type="number"
                            name={question.id}
                            placeholder={`Enter ${question.question}`}
                            required={question.is_required}
                            className="border p-2 w-full rounded"
                            onChange={(e) =>
                              handleInputChange(question.id, e.target.value)
                            }
                          />
                        )}

                        {/* Date Input */}
                        {question.answer_type.toLowerCase() === "date" && (
                          <input
                            type="date"
                            name={question.id}
                            placeholder={`Enter ${question.question}`}
                            required={question.is_required}
                            className="border p-2 w-full rounded"
                            onChange={(e) =>
                              handleInputChange(question.id, e.target.value)
                            }
                          />
                        )}

                        {/* Radio Button Input for Single Choice */}
                        {question.answer_type.toLowerCase() ===
                          "single_choice" && (
                          <div className="space-y-2">
                            {Object.entries(question.options).map(
                              ([key, label]) => (
                                <div key={key} className="flex items-center">
                                  <input
                                    type="radio"
                                    name={question.id}
                                    value={label}
                                    required={question.is_required}
                                    className="mr-2"
                                    onChange={(e) =>
                                      handleInputChange(
                                        question.id,
                                        e.target.value
                                      )
                                    }
                                  />
                                  <label>{label}</label>
                                </div>
                              )
                            )}
                          </div>
                        )}

                        {/* Checkbox Input for Multiple Choice */}
                        {question.answer_type.toLowerCase() ===
                          "multiple_choice" && (
                          <div className="space-y-2">
                            {Object.entries(question.options).map(
                              ([key, label]) => (
                                <div key={key} className="flex items-center">
                                  <input
                                    type="checkbox"
                                    name={question.id}
                                    value={label}
                                    className="mr-2"
                                    onChange={(e) =>
                                      handleCheckboxChange(
                                        question.id,
                                        e.target.checked,
                                        label
                                      )
                                    }
                                  />
                                  <label>{label}</label>
                                </div>
                              )
                            )}
                          </div>
                        )}

                        {/* Boolean Input */}
                        {question.answer_type.toLowerCase() === "boolean" && (
                          <div className="flex items-center space-x-4">
                            <label className="flex items-center">
                              <input
                                type="radio"
                                name={question.id}
                                value="true"
                                required={question.is_required}
                                className="mr-2"
                                onChange={(e) =>
                                  handleInputChange(question.id, e.target.value)
                                }
                              />
                              Yes
                            </label>
                            <label className="flex items-center">
                              <input
                                type="radio"
                                name={question.id}
                                value="false"
                                required={question.is_required}
                                className="mr-2"
                                onChange={(e) =>
                                  handleInputChange(question.id, e.target.value)
                                }
                              />
                              No
                            </label>
                          </div>
                        )}
                      </div>
                    ))}
                  </>
                ) : null}

                {/* Submit and Close Buttons */}
                <div className="flex justify-end gap-4">
                  <button
                    type="submit"
                    className="py-3 px-6 bg-[#147481] text-white rounded hover:bg-white-600 transition"
                  >
                    Submit
                  </button>
                  <button
                    onClick={toggleModal}
                    type="button"
                    className="py-3 px-6 bg-gray-500 text-white rounded hover:bg-gray-700 transition"
                  >
                    Close
                  </button>
                </div>
              </form>
            )}
            {isOtp && check && (
              <form
                onSubmit={handleSubmitOtp}
                className="space-y-6 overflow-auto"
              >
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-2">
                    OTP <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter OTP Code sent To Your Email"
                    required
                    className="border p-2 w-full rounded"
                    onChange={(e) => setOtp(e.target.value)}
                  />
                </div>

                <div className="flex justify-end gap-4">
                  <button
                    type="submit"
                    className="py-3 px-6 bg-[#147481] text-white rounded hover:bg-white-600 transition"
                  >
                    Submit
                  </button>
                  <button
                    onClick={toggleModal}
                    type="button"
                    className="py-3 px-6 bg-gray-500 text-white rounded hover:bg-gray-700 transition"
                  >
                    Close
                  </button>
                </div>
              </form>
            )}
            {!check && (
              <div className="">
                <h4 className="text-xl font-bold mb-6 text-center">
                  Check Your Details
                </h4>
              </div>
            )}
            {tickets && !check && (
              <div className="flex flex-col gap-4">
                {/* Check if the current date is within the sales period */}
                {new Date() < new Date(tickets.sales_start_date) ? (
                  <>
                    <p className="text-red-500">Sales have not started yet.</p>
                    <div className="flex justify-end gap-4">
                      <button
                        onClick={toggleModal}
                        type="button"
                        className="py-3 px-6 bg-gray-500 text-white rounded hover:bg-gray-700 transition"
                      >
                        Close
                      </button>
                    </div>
                  </>
                ) : new Date() > new Date(tickets.sales_end_date) ? (
                  <>
                    <p className="text-red-500">Sales have ended.</p>
                    <div className="flex justify-end gap-4">
                      <button
                        onClick={toggleModal}
                        type="button"
                        className="py-3 px-6 bg-gray-500 text-white rounded hover:bg-gray-700 transition"
                      >
                        Close
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <span>Number of Tickets:</span>
                    <input
                      type="number"
                      min="1"
                      max={tickets.ticket_limit}
                      value={numberOfTickets}
                      onChange={(e) => {
                        const value = Math.min(
                          e.target.value,
                          tickets.ticket_limit
                        ); // Ensure it does not exceed ticket_limit
                        setNumberOfTickets(value);
                      }}
                      className="border p-2 w-full rounded"
                    />

                    <label className="border p-2 w-full rounded">
                      <span>Ticket Price:</span>{" "}
                      <span>
                        ${(tickets?.price * numberOfTickets).toFixed(2)}{" "}
                      </span>{" "}
                      {/* Updated to format to 2 decimal places */}
                    </label>

                    <label className="border p-2 w-full rounded">
                      <span>Service Fee Price:</span>{" "}
                      <span>
                        $
                        {(
                          tickets?.price *
                          tickets?.service_fee *
                          numberOfTickets
                        ).toFixed(2)}{" "}
                      </span>{" "}
                      {/* Updated to format to 2 decimal places */}
                    </label>

                    <label className="border p-2 w-full rounded">
                      <span>Total Price: </span>{" "}
                      <span>${(totalPrice * numberOfTickets).toFixed(2)} </span>{" "}
                      {/* Updated to format to 2 decimal places */}
                    </label>
                    <div className="flex justify-end gap-4">
                      <button
                        type="submit"
                        onClick={handleSubmitFinal}
                        className="py-3 px-6 bg-[#147481] text-white rounded hover:bg-white-600 transition"
                      >
                        Submit
                      </button>
                      <button
                        onClick={toggleModal}
                        type="button"
                        className="py-3 px-6 bg-gray-500 text-white rounded hover:bg-gray-700 transition"
                      >
                        Close
                      </button>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
