import React, { useEffect } from 'react';
import { supabase } from '../api.js';

const StatCard = ({ value, label, description }) => (
  <div className="flex flex-col items-center mb-8 text-center sm:mb-0">
    <div className="mb-2 text-4xl font-bold">{value}</div>
    <div className="mb-1 text-xl font-semibold">{label}</div>
    <div className="text-sm text-gray-600 max-w-[200px]">{description}</div>
  </div>
);

const ROIStats = () => {
  const [events, setEvents] = React.useState([]);
  const [ticketOrders, setTicketOrders] = React.useState([]);
  const [organizers, setOrganizers] = React.useState([]);

  useEffect(() => {
    fetchEvents();
  }, []);

  useEffect(() => {
    fetchTicketOrders();
  }, []);

  useEffect(() => {
    fetchOrganizers();
  }, []);
  const fetchEvents = async () => {
    const currentDate = new Date().toISOString();
    try {
      const { data, error } = await supabase
        .from('events')
        .select('*');
      const { ticketData, ticketError } = await supabase
        .from('ticket_orders')
        .select('*')

      if (error) {
        console.error('Error fetching events:', error.message);
        return; // Exit if there's an error
      }

      console.log('Fetched events:', data);
      console.log('Fetched tickets:', ticketData);
      setEvents(data);
    } catch (error) {
      console.error('Unexpected error:', error);
    }
  };

  const totalTicketOrders = ticketOrders.reduce((accumulator, order) => {
    return accumulator + (order.admission || 0);
  }, 0);

  const fetchTicketOrders = async () => {
    const currentDate = new Date().toISOString();
    try {
      const { data, error } = await supabase
        .from('ticket_orders')
        .select('*')
        setTicketOrders(data);
      console.log('Fetched tickets:', data);
    } catch (error) {
      console.error('Unexpected error:', error);
    }
  }

  const fetchOrganizers = async () => {
    try {
      const { data, error } = await supabase
        .from('organizations')
        .select('*')
        console.log('Fetched organizers:', data);
        setOrganizers(data);
    } catch {
      console.error('Unexpected error:');
    }
  }
  
  return (
    <div className="flex flex-col items-center justify-around p-4 bg-white sm:flex-row sm:items-start sm:p-8">
      <StatCard
        value={`${(events.length - 60 || 0).toLocaleString()}`}
        label="Events Hosted"
        description=""
      />
      <StatCard
        value={`${(totalTicketOrders * 6).toLocaleString()}+`}
        label="Tickets Sold"
        description=""
      />
      <StatCard
        value={organizers.length || 0}
        label="Happy Organizers"
        description=""
      />
    </div>
  );
};

export default ROIStats;