import React, { useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { supabase } from "../api.js";
import { isMobile } from "react-device-detect";
import Footerhome from "../components/Footerhome";
import PaymentForm from "../components/PaymentForm.js";
import { MakePayment } from "../utils/paynow.js";

const EventDetails = () => {
  const [event, setEvent] = useState(null);
  const [tickets, setTickets] = useState([]);
  const [organization, setOrganization] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null); // Change state to hold the whole ticket object

 
  const [searchParams] = useSearchParams();
  const id = searchParams.get("event-id");

  useEffect(() => {
    fetchEventDetails();
  }, [id]);

  const fetchEventDetails = async () => {
    try {
      setLoading(true);
      const { data: eventData, error: eventError } = await supabase
        .from("events")
        .select("*, organizations(name)")
        .eq("id", id)
        .eq("publish_status", "published")
        .single();

      if (eventError) throw eventError;

      const { data: ticketsData, error: ticketsError } = await supabase
        .from("tickets")
        .select("*")
        .eq("event_id", id);
      const { data: QuestionData, error: QuestionError } = await supabase
        .from("event_custom_questions")
        .select("*")
        .eq("event_id", id);
      if (ticketsError) throw ticketsError;
      if (QuestionError) {
        throw QuestionError;
      }
      setQuestions(QuestionData);
      setEvent(eventData);
      setTickets(ticketsData);
      setOrganization(eventData.organizations);
    } catch (error) {
      console.error("Error fetching event details:", error.message);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const scrollToFooter = () => {
    const footer = document.getElementById("event-footer");
    if (footer) {
      footer.scrollIntoView({ behavior: "smooth" });
    }
  };

  function getDayName(dateStr, locale) {
    var date = new Date(dateStr);
    return date.toLocaleDateString(locale, {
      weekday: "long",
      day: "2-digit",
      month: "long",
      year: "numeric",
    });
  }

  const handlePayment = async (event, email, ticket) => {
    try {
      setLoading(true);
      const data = await MakePayment(event, email, ticket);
      console.log(data);
      setLoading(false);

      if (data.redirectUrl) {
        window.location.href = data.redirectUrl;
      }
    } catch (error) {
      console.error("Error during payment:", error.message);
      setLoading(false);
    }
  };

  const day = getDayName(event?.start_time, "en-GB");

  console.log("day", day);
  console.log("Questions", questions);
  console.log("tickets", tickets);

  const totalPrice = tickets.reduce((accumulator, ticket) => {
    return accumulator + (ticket.price || 0);
  }, 0);
  console.log("eve", event?.event_url);

  if (loading)
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="w-16 h-16 border-4 border-[#147481] rounded-full border-t-transparent animate-spin"></div>
      </div>
    );

  if (error)
    return (
      <div className="flex items-center justify-center min-h-screen text-red-500">
        Error: {error}
      </div>
    );

  if (!event)
    return (
      <div className="flex items-center justify-center min-h-screen">
        No event found
      </div>
    );

  return (
    <div className="min-h-screen bg-white">
      <header className="flex items-center justify-center mt-20 text-center bg-white border-b border-gray-200">
        <div className="container px-4 py-4 mx-auto">
          <h1 className="text-2xl font-bold text-gray-800">{event.name}</h1>
        </div>
      </header>

      <main className="container px-4 py-8 mx-auto">
        <div className="flex justify-center w-full max-w-[1200px] mx-auto mb-6">
          <div className="relative w-full max-w-3xl overflow-hidden rounded-lg shadow-md aspect-video">
            <img
              src={event.poster_url || "/default-image-url.jpg"}
              alt={event.name}
              className="absolute inset-0 object-contain w-full h-full"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent"></div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row lg:space-x-8">
          <div className="lg:w-2/3">
            <div className="p-6 bg-gray-100 rounded-lg">
              <h2 className="mb-4 text-xl font-semibold">About this event</h2>
              <p className="mb-2">
                <strong>Date</strong>
              </p>
              <p className="mb-4 text-sm text-gray-600">
                {getDayName(event.start_time)}
              </p>
              <p className="mb-2">
                <strong>Location</strong>
              </p>
              <p className="mb-4 text-sm text-gray-600">
                {event.venue}, {event.address}
              </p>
              <h3 className="mb-2 font-semibold">Description</h3>
              <p className="text-sm text-gray-700">{event.description}</p>
            </div>
            <div className="p-4 mt-4 bg-gray-100 rounded-lg">
              <h2 className="mb-4 text-xl font-semibold">Event Organizer</h2>
              {organization && <p className="mb-2">{organization.name}</p>}{" "}
            </div>
          </div>

          <div className="mt-8 lg:mt-0 md:w-[340px]">
            <div className="sticky p-6 bg-white border border-gray-200 rounded-lg shadow-sm top-8">
              <h3 className="mb-4 text-xl font-semibold">Tickets</h3>
              {tickets.map((ticket, index) => (
                <>
                  <div key={index} className="mb-4">
                    <p className="font-semibold">{ticket.name}</p>
                    <p className="text-2xl font-bold">
                      ${ticket.price ? ticket.price.toFixed(2) : "0.00"}
                    </p>
                  </div>

                  {/* <button onClick={
                                    isMobile ? () => window.location.href = `${
                                        event.event_url
                                    }` : () =>handlePayment(event, ticket.name, ticket.price.toFixed(2))
                                }
                                className="block w-full py-3 mt-4 text-center text-white bg-[#147481] rounded hover:bg-white-600">
                                {totalPrice > 0 ? 'Get Tickets On The App' : 'Register On The App'}
                            </button>                          */}
                </>
              ))}
              <label className="block text-sm font-medium mb-2">
                Select Ticket
              </label>
              <select
                className="border p-2 w-full rounded"
                value={selectedTicket?.id || ""} // Use ticket ID for the select value
                onChange={(e) => {
                  const selected = tickets.find(
                    (ticket) => ticket.id === e.target.value
                  );
                  setSelectedTicket(selected); // Update state with the whole ticket object
                }}
              >
                <option value="" disabled>
                  Select a ticket
                </option>
                {tickets.map((ticket, index) => (
                  <option key={index} value={ticket.id}>
                    {ticket.name}
                  </option>
                ))}
              </select>

              {selectedTicket ? (
                <PaymentForm
                  questions={questions}
                  event={event}
                  tickets={selectedTicket}
                  onPayment={handlePayment}
                />
              ) : (
                <p className="text-red-500 mt-2">
                  Please select a ticket to proceed with payment.
                </p>
              )}
            </div>
          </div>
        </div>
      </main>

      <footer id="event-footer" className="mt-12 border-t border-gray-200">
        <Footerhome />
      </footer>
    </div>
  );
};

export default EventDetails;
